.contact-container {
  background: linear-gradient(to top, #002c4b 0%, #00223b 50%);
  color: white;
  text-align: center;
  padding: 5px 20px;
  width: 75%;
  margin: 0 auto;
  border-radius: 10px 10px 0px 0px;
  max-width: 1100px;
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.8); 
}

.headline {
  font-size: 3em;
  margin-bottom: 0px;
  font-weight: 500;
  color: white;
  padding-top: 1%;
}

.author-info{
  font-size: .8em;
}

.back-to-top-button {
  position: fixed;
  bottom: -50px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #002c4b;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out, bottom 0.3s ease-in-out;
  cursor: pointer;
}

.back-to-top-button svg {
  width: 24px;
  height: 24px;
}

.back-to-top-button:hover {
  background-color: #004080;
}

.back-to-top-button.show {
  bottom: 10%; /* Adjust this value to move the button down */
  transform: translateY(-80%);
}
