.popup-description {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add space between sections */
    margin-top: 20px;
}

.popup-section {
    display: flex;
    align-items: flex-start; /* Align icons to the top of the text */
    gap: 15px;
    padding: 10px 0; /* Add padding to separate the sections visually */
    border-bottom: 1px solid #eee; /* Add a subtle bottom border for separation */
}

.popup-section:last-child {
    border-bottom: none; /* Remove the border for the last section */
}

.popup-icon {
    font-size: 1.5em;
    color: #007bff;
    margin-top: 5px; /* Align icons with the text */
}

.popup-section-title {
    font-weight: bold;
    color: #333;
}

.popup-section-description {
    margin: 5px 0 0 0;
    color: #555;
    font-size: 0.9em; /* Slightly smaller font for descriptions */
}
