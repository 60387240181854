#contact-us {
  background: linear-gradient(to top, #002c4b 0%, #00223b 50%);
  color: white;
  text-align: center;
  padding: 20px;
  width: 75%;
  margin: 0 auto;
  border-radius: 10px;
  max-width: 1100px;
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.8);
}

#contact-us h1 {
  font-size: 3em;
  margin-top: 10px;
  color: white;
  margin-bottom: 0;
}

#contact-us form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-us label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: white;
}

#contact-us input[type="text"],
#contact-us input[type="email"],
#contact-us textarea {
  width: 85%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid grey;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  font-size: 1em;
}

#contact-us input[type="text"]:focus,
#contact-us input[type="email"]:focus,
#contact-us textarea:focus {
  border-color: white;
  outline: none;
}

#contact-us button {
  width: 100px;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1em;
  cursor: pointer;
}

#contact-us button:hover {
  background-color: #0056b3;
}
