/* Global styles */
html, body {
  margin: 0; /* To remove any default margin */
  padding: 0; /* To remove any default padding */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  height: 100%; /* Ensures the body takes up full height of the viewport */
}



body {
  position: relative; /* Ensure the pseudo-element is positioned relative to the body */
  background-image: url('/public/photos/MC-Digital-Dev.png');
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center center; /* Centers the image within the container */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  min-height: 100vh; /* Ensures the body is at least the height of the viewport */
}

/* Overlay effect */
body::before {
  content: '';
  position: fixed; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the entire viewport height */
  background-color: rgba(0, 0, 0, 0.05); /* Very slight transparent overlay */
  pointer-events: none; /* Allow interactions with elements underneath */
  z-index: 1; /* Ensure the overlay is on top */
}

/* Ensure the main content is above the overlay */
#root {
  position: relative;
  z-index: 2;
}

/* Apply box-sizing only to the header and its children */
#header, #header * {
  box-sizing: border-box;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  body {
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: top center; /* Adjusts the position for mobile view */
  }
}
