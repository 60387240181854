.back-content-description {
    color: #000000; 
    text-align: center; 
    max-width: 70%; 
    margin: 50px auto; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  @media (max-width: 600px) {
    .back-content-description {
      display: none;
    }
  }
  