.testimonials-container {
  background: linear-gradient(to top, #002c4b 0%, #00223b 50%);
    color: white;
    text-align: center;
    padding: 20px 20px;
    width: 75%;
    margin: 0 auto;
    border-radius: 10px;
    max-width: 1100px;
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.8); /* Ensure padding and border are included in the width */
}

.testimonials-title {
  font-size: 3em;
  margin: 20px 0 20px 0;
  color: #ffffff;
  font-weight: bold;
}

.testimonials-paragraph {
  margin-bottom: 20px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  
}

.testimonial {
  margin: 20px auto 40px auto; /* Top, horizontal (left and right), bottom */
  width: 70%;
  background: linear-gradient(to bottom, #ffffff 0%, #bebebe 100%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}


.testimonial:hover {
  transform: translateY(-10px);
}

.testimonial-content {
  font-size: 1.3em;
  line-height: 1.6;
  margin-bottom: 20px;
  font-style: italic;
  color: #353535;
}

.testimonial-author {
  font-size: 1.1em;
  color: #1b1e22;
  font-weight: bold;
}
