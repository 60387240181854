.hero-section {
    height: 65vh;
    display: flex;
    justify-content: flex-start; /* Align content to the left */
    align-items: center;
    padding-top: 60px;
    padding-left: 0; /* Remove left padding */
    position: relative;
}

/* Larger screens */
@media screen and (min-width: 1024px) {
    .hero-section {
        height: 70vh; /* increased height for larger screens */
    }
}

/* Smaller screens */
@media screen and (max-width: 768px) {
    .hero-section {
        height: 60vh; /* adjust as needed */
    }
}
