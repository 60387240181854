/* footer.css */

.card-title {
  font-size: 1.5em;
  margin-bottom: 0px;
  font-weight: 600;
  text-align: center;
  color: white;
  margin-top: 50px;
}



.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.footer-container {
  background: linear-gradient(to top, #002c4b 0%, #00223b 50%);
  color: white;
  text-align: center;
  padding: 0px 20px;
  width: 75%;
  margin: 0 auto;
  border-radius: 10px;
  max-width: 1100px;
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.8);
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto;
  padding-top: 2%;
}

.centered-title {
  text-align: center;
  max-width: 70%;
  margin: auto;
  padding-bottom: 1%;
  font-size: 18px;
  font-weight: 500;
}

.project-title {
  font-size: 3em;
  margin-bottom: 1%;
  padding-top: 1%;
}

.header-line {
  border: none;
  height: 2px;
  background-color: grey;
  width: 50%;
  margin: 10px auto;
  margin-bottom: 1%;
}

.header-line-under {
  border: none;
  height: 2px;
  background-color: grey;
  width: 10%;
  margin: 10px auto;
}

.header-line-under-cards {
  border: none;
  height: 2px;
  background-color: grey;
  width: 5%;
  
}

.separater {
  background-image: url('/public/photos/hero-background2.png');
  background-attachment: fixed;
  background-size: cover;
  background-position: center 100%;
  background-repeat: no-repeat;
  color: rgb(255, 255, 255);
  text-align: center;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  padding-bottom: 15%;
  margin-top: 8%;
}

@media (max-width: 768px) {
  .header-line {
    width: 70%;
  }

  .card-container {
    flex-direction: column;
    align-items: center;
  }
  .card{
    max-width: 300px;
    max-height: 180px;
  }
}

@media (max-width: 950px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-container {
    width: 75%;
  }
}

@media (max-width: 575px) {
  .icon-container {
    max-width: 340px;
    margin: auto;
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fadeUp 1s ease-out;
}

.animate {
  animation: fadeUp 1s ease-out forwards;
}

.project-details-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 10;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.project-details-container.show {
  transform: translateY(0);
}

.project-details-container, .h3 {
  font-size: 15px;
  letter-spacing: .1px;
}
