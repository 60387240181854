@media (max-width: 1924px) {
  .project-details-container {
    flex-direction: column !important;
  }
  
  .project-details-image {
    margin-top: 5%;
    width: 90%;
  }
  
  .project-details-title {
    margin-top: 20%;
  }

  .project-details-description {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  
  .project-details-button {
    display: flex;
    justify-content: space-around; /* Use space-around for reduced gap */
    width: 40%; /* Adjust width to reduce gap */
    margin: 20px auto; /* Center the buttons container */
  }

  .project-details-button button {
    flex: 1; /* Ensure buttons take up equal space */
    margin: 0 10px; /* Add horizontal margin to create space between buttons */
  }
}

@media (max-width: 600px) {
  .project-details-container {
    flex-direction: column !important;
  }
  
  .project-details-image {
    margin-top: 7%;
    width: 90%; /* Ensure the image takes full width */
  }
  
  .project-details-title {
    margin-top: 5%; /* Reduce margin-top for smaller screens */
  }
  
  .project-details-description,
  .project-details-built {
    margin-top: 0%; /* Reduce margin-top for smaller screens */
  }

  .project-details-button {
    flex-direction: column;
    align-items: center;
    width: auto; /* Ensure full width on smaller screens */
  }

  .project-details-button button {
    margin-bottom: 10px; /* Add space between buttons on small screens */
    width: auto;
  }
}
