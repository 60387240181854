.about-us-container {
  background: linear-gradient(to top, #002c4b 0%, #00223b 50%);
  color: white;
  text-align: center;
  padding: 20px;
  width: 75%;
  margin: 0 auto;
  border-radius: 10px;
  max-width: 1100px;
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.8);
}

.about-us-title {
  padding-top: 20px;
  font-size: 3em;
  margin-bottom: 20px;
}

.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.about-us-description {
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.about-us-description p {
  font-weight: 400 !important;
  color: #e0e0e0;
}

.header-line-under {
  background-color: grey;
  border: none;
  height: 2px;
}

.connect-button {
  background-color: transparent;
  color: #e0e0e0;
  border: 2px solid transparent;
  padding: 10px 20px;
  font-size: 1.3em;
  margin-top: 20px;
  cursor: pointer;
  transition: border 0.3s ease;
  border-radius: 10px;
}

.connect-button:hover {
  border: 2px solid #e0e0e0;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .about-us-description {
    width: 85%;
  }
}
