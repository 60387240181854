.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  text-align: left;
  background: transparent;
  padding-left: 10%; /* Adjust padding as needed */
}

.welcome-name {
  font-size: 4em;
  color: black;
  margin: 0;
  font-weight: bold;
  animation: typing 4s steps(40, end), blink-caret .75s step-end infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: .15em solid black;
}

.welcome-tagline {
  font-size: 2em;
  color: black;
  margin: 50px 0;
  max-width: 600px;
}

.buttons {
  display: flex;
  gap: 20px;
}



.welcome-button.secondary-custom {
  background-color: #002c4b;
  border: 2px solid #002c4b;
  color: #ffffff;
}

.welcome-button.secondary-custom:hover {
  background-color: transparent;
  color: #002c4b;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes typing-tablet {
  from { width: 0 }
  to { width: 35% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: black }
}

@media (max-width: 950px) {
  .welcome-container {
    align-items: center;
    padding-left: 0;
    text-align: center;
  }
  .welcome-name {
    font-size: 1.8em;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 3px;
    animation: typing 4s steps(40, end), blink-caret .75s step-end infinite;
  }
  .welcome-tagline {
    font-size: 1.5em;
    max-width: 80%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
  }
  .buttons {
    justify-content: center;
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 950px) {
  .welcome-name {
    animation: typing-tablet 4s steps(40, end), blink-caret .75s step-end infinite;
  }
  .welcome-tagline {
    max-width: 70%;
  }
}

@media (max-width: 600px) {
  .welcome-name {
    max-width: 80%;
  }
}
