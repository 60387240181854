.services-section {
    background: linear-gradient(to top, #002c4b 0%, #00223b 50%);
    color: white;
    text-align: center;
    padding: 10px 20px;
    width: 75%;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 50px;
    max-width: 1100px;
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.8);
}

.services-section h2 {
    font-size: 2em;
    margin-bottom: 30px;
}

.services-section p {
    font-size: 1.2em;
    margin: 0 auto 0 auto; /* Center the paragraph using margin auto */
    width: 70%; /* Set width to 70% */
}

.services-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.service-card {
    background-color: #f9f9f9;  /* Soft white background */
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    color: black; /* Set all text color to black */
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: black;
}

.service-card p {
    margin-bottom: 10px;
    color: black;
}

.service-card .starting-at {
    font-size: 0.9em;
    margin-bottom: 2px; /* Reduce the margin-bottom to reduce the space */
    color: black;
}

.service-card .price {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 2px; /* Reduce the margin-bottom to reduce the space */
    color: black;
    margin-top: 0;
}

.service-card .billed-annually {
    font-size: 0.9em;
    color: grey;
    margin-top: 2px; /* Adjust the margin-top to fine-tune the spacing */
    margin-bottom: 20px;
}

.service-card ul {
    list-style-type: none;
    padding: 0;
    border-top: 1px solid #ddd;
    margin-top: 20px;
}

.service-card ul li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    display: flex;
    align-items: center;
    color: black; /* Set list item text color to black */
}

.service-card ul li:last-child {
    border-bottom: none;
}

.service-card ul li svg {
    color: #007bff;
    margin-right: 10px;
}

.services-button {
    background-color: transparent;
    color: #e0e0e0;
    border: 2px solid transparent;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.3em;
    margin-top: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: border 0.3s ease;
}

.services-button:hover {
    border: 2px solid #e0e0e0;
}

/* Specific style for the middle card */
.services-cards .service-card:nth-child(2) {
    background: linear-gradient(to bottom, #eef4ff, #ddeaff);  /* Slight blue hue gradient */
    border: 2px solid #007bff;
}

.services-cards .service-card:nth-child(2) ul {
    border-top: 1px solid #007bff;
}

.services-cards .service-card:nth-child(2) ul li {
    border-bottom: 1px solid #007bff;
}

.services-cards .service-card:nth-child(2) ul li:last-child {
    border-bottom: none;
}

