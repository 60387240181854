.separator {
  background-image: url('/public/photos/hero-background2.png');
  background-attachment: fixed;
  background-size: cover;
  background-position: center 100%;
  background-repeat: no-repeat;
  color: rgb(255, 255, 255);
  text-align: center;
  padding-top: 0px;
  display: flex;
  justify-content: center; 
  margin-top: 15%;
}